import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Keyboard, Mousewheel} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import s from './SliderGallery.scss';
import {withGlobals} from '../../../globalPropsContext';
import {SliderGalleryProps} from '../SliderGalleryOld/SliderGallery';
import {inlineStyleFix} from '../../../styles/inlineStyle';
import {useStyles} from '@wix/tpa-settings/react';
import {useStylesParams} from '../../../stylesParamsContext';
import {GridType} from '../../../types/galleryTypes';
import classnames from 'classnames';
import type {Swiper as SwiperClass} from 'swiper/types';
import {SliderGalleryTitle} from './SliderGalleryTitle/SliderGalleryTitle';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import type {SwiperProps} from 'swiper/swiper-react';
import {ArrowsNavigationWrapper} from './ArrowsNavigationWrapper/ArrowsNavigationWrapper';
import {ProductPlaceholder} from './ProductPlaceholder/ProductPlaceholder';
import {ProductItemSlide} from './ProductItemSlide/ProductItemSlide';
import {DotNavigation} from 'wix-ui-tpa/cssVars';
import {Experiments} from '../../../constants';
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor';

export enum SliderGalleryDataHook {
  Root = 'SliderGalleryDataHook.Root',
  Slide = 'SliderGalleryDataHook.Slide',
  SliderAnnouncer = 'SliderGalleryDataHook.SliderAnnouncer',
  Navigation = 'SliderGalleryDataHook.Navigation',
}

/* eslint-disable sonarjs/cognitive-complexity */
export const SliderGallery: React.FC<SliderGalleryProps> = withGlobals((props: SliderGalleryProps) => {
  const {globals, host, onAppLoaded, hideGallery, isLoaded} = props;
  const {loadPrevProductsIfNeeded, loadNextProductsIfNeeded} = globals;

  const {experiments} = useExperiments();
  const {isMobile} = useEnvironment();
  const newSliderPaginationEnabled = experiments.enabled(Experiments.NewSliderGalleryPagination);

  const rootRef = useRef<HTMLDivElement>(null);
  const swiperContainerRef = useRef<HTMLDivElement>(null);
  const [swiper, setSwiper] = useState<SwiperClass>(null);
  const [maxSwiperWidth, setMaxSwiperWidth] = useState<string>('inherit');
  const [swiperCurrentIndex, setSwiperCurrentIndex] = useState<number>(0);
  const [a11yAnnouncer, setA11yAnnouncer] = useState<Announcer>(null);
  const styles = useStyles();
  const stylesParams = useStylesParams();
  const autoGrid = styles.get(stylesParams.gallery_gridType) === GridType.AUTO;

  const getBreakpointsArray = useCallback(() => {
    const minProductWidth =
      styles.get(stylesParams.gallery_productSize) + styles.get(stylesParams.gallery_gapSizeColumn);
    const maxResolution = 10000;
    const breakpoints = {};

    for (let breakpoint = 0; breakpoint <= maxResolution; breakpoint += minProductWidth) {
      const slidesPerView = Math.max(Math.floor(breakpoint / minProductWidth), 1);
      breakpoints[breakpoint] = {slidesPerView};
    }

    return breakpoints;
  }, [styles, stylesParams]);

  useEffect(() => {
    if (!autoGrid) {
      return;
    }

    // istanbul ignore next: cant test with jsdom, will be tested by sled
    const resizeObserver = new ResizeObserver(() => {
      const width = rootRef.current?.clientWidth;
      if (width) {
        setMaxSwiperWidth(`${width}px`);
      }
    });

    resizeObserver.observe(rootRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [autoGrid]);

  useEffect(() => {
    host.registerToComponentDidLayout(reportAppLoaded);
    setA11yAnnouncer(new Announcer(SliderGalleryDataHook.SliderAnnouncer));
    setTimeout(() => swiperContainerRef.current?.classList.remove(s.ssrFix), 0);
    loadPrevProductsIfNeeded?.(0);

    return () => {
      a11yAnnouncer?.cleanup();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportAppLoaded = () => {
    if (globals.isInteractive) {
      onAppLoaded?.();
    }
  };

  const isEmptyState = (): boolean => {
    const {products, isCategoryVisible} = globals;
    return !products?.length || !isCategoryVisible;
  };

  const renderPlaceholderSlides = () => {
    const numberOfPlaceholders = autoGrid ? 40 : 6;
    return (
      <>
        {Array.from({length: numberOfPlaceholders}, (_, i) => (
          <SwiperSlide key={`slider_placeholder_${i}`} className={s.swiperSlide}>
            <div data-hook={SliderGalleryDataHook.Slide} className={s.productItemSlide}>
              <ProductPlaceholder />
            </div>
          </SwiperSlide>
        ))}
      </>
    );
  };

  const renderSlides = () => {
    return globals.products.map((product, i) => (
      <SwiperSlide key={i} className={s.swiperSlide}>
        <ProductItemSlide
          dataHook={SliderGalleryDataHook.Slide}
          className={s.productItemSlide}
          product={product}
          index={i}
          a11yAnnouncer={a11yAnnouncer}
        />
      </SwiperSlide>
    ));
  };

  const shouldNotShowSlider = () => {
    const {isCategoryVisible, isEditorMode} = globals;
    return !isLoaded || hideGallery || (!isEditorMode && !isCategoryVisible);
  };

  if (shouldNotShowSlider()) {
    return null;
  }

  const swiperConfig: SwiperProps = {
    modules: [Mousewheel, Keyboard],
    onSwiper: setSwiper,
    slidesPerView: 'auto',
    keyboard: {enabled: true},
    threshold: 2,
    loop: true,
    breakpoints: autoGrid && getBreakpointsArray(),
    breakpointsBase: 'container',
    mousewheel: {forceToAxis: true, sensitivity: 0.1, releaseOnEdges: true},
    onSlideNextTransitionStart: (_swiper) => loadNextProductsIfNeeded(_swiper.realIndex),
    onSlidePrevTransitionStart: (_swiper) => loadPrevProductsIfNeeded(_swiper.realIndex),
    onTransitionEnd: (_swiper) => setSwiperCurrentIndex(_swiper.realIndex),
  };

  return (
    <div data-hook={SliderGalleryDataHook.Root} className={s.root} ref={rootRef}>
      <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
      <SliderGalleryTitle />
      <div
        ref={swiperContainerRef}
        style={{'--maxWidth': maxSwiperWidth} as any}
        className={classnames(s.swiperContainer, {[s.autoGrid]: autoGrid, [s.ssrFix]: autoGrid})}>
        <ArrowsNavigationWrapper
          navigateNext={/* istanbul ignore next: todo swiper - add test */ () => swiper?.slideNext()}
          navigatePrev={/* istanbul ignore next: todo swiper - add test */ () => swiper?.slidePrev()}>
          <Swiper className={s.swiperRoot} {...swiperConfig}>
            {isEmptyState() ? renderPlaceholderSlides() : renderSlides()}
          </Swiper>
          {isMobile && newSliderPaginationEnabled && (
            <div role="navigation" className={s.dotNavigationWrapper}>
              <DotNavigation
                aria-label={swiperCurrentIndex.toString()}
                data-hook={SliderGalleryDataHook.Navigation}
                currentIndex={swiperCurrentIndex}
                length={globals.totalProducts}
              />
            </div>
          )}
        </ArrowsNavigationWrapper>
      </div>
    </div>
  );
});
